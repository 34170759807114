/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useState } from "react"
import "../footer/css/index.css"
import { BackTop, Col, Image, Row, Tooltip, Input, Button, Modal } from "antd"
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"
import { useTranslation } from 'react-i18next'

function Footer() {

    const navigate = useNavigate()
    const { width } = useDimensions()

    const { t, i18n } = useTranslation()

    const [isModalLineVisible, setIsModalLineVisible] = useState(false)
    const [isModalWhatsappVisible, setIsModalWhatsappVisible] = useState(false)

    const handleModalLine = () => {
        setIsModalLineVisible(false)
    }

    const handleModalWhatsapp = () => {
        setIsModalWhatsappVisible(false)
    }

    return (
        <footer>
            <div
                style={{
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#262626",
                }}
            >
                <nav
                    style={{

                        color: "white",
                        // paddingLeft: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
                        // paddingRight: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
                        paddingTop: 84,
                        paddingBottom: 84,
                        maxWidth: 1000,
                    }}
                >
                    <Row gutter={[0, 24]}>
                        <Col xs={24} md={12} xl={8}>
                            <div style={{ display: "grid", paddingLeft: (width > 1200 ? 0 : 24) }}>
                                <div>
                                    <div style={{ paddingBottom: 12 }}>
                                        <label style={{ fontSize: 24, fontWeight: 600 }}>BUDDY TOUR SERVICE</label>
                                    </div>

                                    <div style={{ paddingBottom: 18 }}>
                                        <label style={{}}>{t('address')}</label>
                                    </div>
                                </div>

                                <div style={{ display: "grid", alignItems: "flex-start", paddingLeft: (width > 767 ? 0 : 24) }}>
                                    <div
                                        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                        onClick={() => {
                                            window.open("tel:+66926930316")
                                        }}
                                    >
                                        <div style={{ height: 70, width: 70, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0085FF", borderRadius: "16px 0px 0px 16px" }}>
                                            <Icon icon="mingcute:phone-fill" style={{ fontSize: 40, color: "white" }} />
                                        </div>

                                        <div style={{ height: 70, minWidth: 180, backgroundColor: "white", borderRadius: "0px 16px 16px 0px" }}>
                                            <div style={{ padding: 12 }}>
                                                <label style={{ fontSize: 16, color: "black", cursor: "pointer" }}>{t('footer-call')}</label>
                                                <br />
                                                <label style={{ fontSize: 16, fontWeight: 600, color: "black", cursor: "pointer" }}>092-693-0316</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center", paddingTop: 18 }}>
                                        <div style={{ paddingLeft: 0 }}>
                                            <Button
                                                type="primary"
                                                style={{ width: 38 }}
                                                size="middle"
                                                onClick={(e) => {
                                                    window.open("https://www.facebook.com/profile.php?id=61558731989718&mibextid=LQQJ4d")
                                                }}
                                            >
                                                <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                    <Icon icon="cib:facebook-f" style={{ color: "white", width: 20, height: 20 }} />
                                                </div>
                                            </Button>
                                        </div>

                                        <div style={{ paddingLeft: 10 }}>
                                            <Button
                                                type="primary"
                                                style={{ width: 38 }}
                                                size="middle"
                                                onClick={(e) => {
                                                    setIsModalLineVisible(true)
                                                }}
                                            >
                                                <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                    <Icon icon="bi:line" style={{ color: "white", width: 20, height: 20 }} />
                                                </div>
                                            </Button>
                                        </div>

                                        <div style={{ paddingLeft: 10 }}>
                                            <Button
                                                type="primary"
                                                style={{ width: 38 }}
                                                size="middle"
                                                onClick={(e) => {
                                                    setIsModalWhatsappVisible(true)
                                                }}
                                            >
                                                <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                    <Icon icon="icomoon-free:whatsapp" style={{ color: "white", width: 20, height: 20 }} />
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={8}>

                        </Col>

                        <Col xs={24} md={12} xl={8}>
                            <div style={{ paddingRight: (width > 1200 ? 0 : 24), paddingLeft: (width > 767 ? 0 : 24) }} >
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.367068002486!2d100.8989977!3d12.9178896!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310295001aa2e0e9%3A0xa47ca1f6a8ee22f!2sBUDDY%20TOUR%20SERVICE%20CO.%2C%20LTD.!5e0!3m2!1sth!2sth!4v1717047694642!5m2!1sth!2sth" style={{ border: 0, width: (width > 700 ? 400 : 300), height: 300 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </Col>
                    </Row>
                </nav>
            </div>

            <nav style={{ backgroundColor: "#0085FF", color: "white", paddingLeft: '5%', paddingRight: '5%', paddingTop: 15, paddingBottom: 15, fontWeight: 600, textAlign: 'center' }}>
                <label>Copyright © 2024 BUDDY TOUR SERVICE All Right Reserved.</label>
            </nav>

            <Modal
                title="ไลน์"
                visible={isModalLineVisible}
                onCancel={handleModalLine}
                width={400}
                footer={[]}
            >
                <img
                    style={{ objectFit: "cover", width: "100%", borderRadius: 8 }}
                    src={`./assets/images/contact/line-qrcode.png`}
                />
            </Modal>

            <Modal
                title="WhatsApp"
                visible={isModalWhatsappVisible}
                onCancel={handleModalWhatsapp}
                width={400}
                footer={[]}
            >
                <img
                    style={{ objectFit: "cover", width: "100%", borderRadius: 8 }}
                    src={`./assets/images/contact/whatsapp-qrcode.jpg`}
                />
            </Modal>
        </footer>
    )
}

export default Footer
