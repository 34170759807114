/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Row,
    Col
} from 'antd'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import { useTranslation } from 'react-i18next'

export default function AboutUs() {

    const { width } = useDimensions()

    const { t, i18n } = useTranslation()

    return (
        <>
            <Header />

            <div
                style={{
                    width: '100% auto',
                    height: '100% auto',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        paddingTop: width > 1200 ? 150 : (width > 760 && width < 1200 ? 150 : 150),
                        paddingBottom: 82,
                        paddingLeft: (width > 1200 ? "12%" : 24),
                        paddingRight: (width > 1200 ? "12%" : 24),
                        maxWidth: 1200,
                        width: "100%"
                    }}
                >
                    <div style={{ paddingBottom: 18 }}>
                        <label style={{ fontWeight: 600, fontSize: 26, color: "#0085FF" }}>{t('about-us-title')}</label>
                    </div>

                    <Row>
                        <Col xs={24} md={6} xl={6}>
                            <img src={"./assets/images/logo/logo.jpg"} style={{ width: "100%", borderRadius: 16, paddingBottom: 18 }} />
                        </Col>
                        <Col xs={24} md={18} xl={18} style={{ paddingLeft: (width > 769 ? 24 : 0) }}>
                            <div style={{ paddingBottom: 18 }}>
                                <label>{t('about-us-detail1')}</label>
                            </div>

                            <div style={{ paddingBottom: 18 }}>
                                <label>{t('about-us-detail2')}</label>
                            </div>

                            <div style={{ paddingBottom: 18 }}>
                                <label>{t('about-us-detail3')}</label>
                            </div>

                            <div style={{ paddingBottom: 0 }}>
                                <label>{t('about-us-detail4')}</label>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Footer />
        </>
    )
}
