/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
    Row,
    Col,
    Modal
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { useTranslation } from 'react-i18next'

export default function Contact() {
    const { width } = useDimensions()

    const [isModalLineVisible, setIsModalLineVisible] = useState(false)

    const { t, i18n } = useTranslation()

    const handleModalLine = () => {
        setIsModalLineVisible(false)
    }

    return (
        <div
            style={{
                paddingTop: 84,
                paddingBottom: 84,
                paddingLeft: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24),
                paddingRight: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24),
                backgroundColor: "#EEEEEE"
            }}
        >
            <Row gutter={[24, 32]}>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: 26 }}>“ {t('home-conact-title')} “</label>
                </Col>

                <Col span={24}>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            window.open("tel:+66926930316")
                        }}
                    >
                        <div style={{ backgroundColor: "#0085FF", color: "white", padding: 24, display: "flex", alignItems: "center", borderRadius: 100 }}>
                            <Icon icon="mingcute:phone-fill" style={{ fontSize: 60, color: "white" }} />
                            <div style={{ display: "grid", justifyContent: "flex-start", paddingLeft: 24 }}>
                                <div>
                                    <label style={{ fontSize: 20, fontWeight: 400, cursor: "pointer" }}>{t('home-conact-call-now')}</label>
                                </div>

                                <div>
                                    <label style={{ fontSize: 32, fontWeight: 600, paddingBottom: 0, marginBottom: 0, cursor: "pointer" }}>092-693-0316</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col span={24}>
                    <Row>
                        <Col xs={24} md={7} xl={7} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div>
                                <div
                                    style={{
                                        backgroundColor: "#0085FF",
                                        color: "white",
                                        borderRadius: "100%",
                                        display: "grid",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 200,
                                        height: 200,
                                        padding: 24,
                                        textAlign: "center"
                                    }}
                                >
                                    <div style={{ paddingTop: 12, paddingLeft: 6, paddingRight: 6 }}>
                                        <label style={{ fontSize: 20, fontWeight: 400 }}>{t('home-conact-outstanding1')}</label>
                                    </div>
                                    
                                    <center>
                                        <img src={"./assets/images/icon/salute.png"} style={{ width: 100, height: 100 }} />
                                    </center>
                                </div>
                            </div>
                        </Col>

                        <Col
                            xs={24} md={1} xl={1}
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                            <div>
                                <img
                                    src={width > 768 ? "./assets/images/icon/right-arrow.png" : "./assets/images/icon/down-arrow.png"}
                                    style={{
                                        width: 40,
                                        height: 40,
                                        paddingLeft: width > 768 ? 13 : 0,
                                        paddingTop: width > 768 ? 0 : 13
                                    }}
                                />
                            </div>
                        </Col>

                        <Col xs={24} md={8} xl={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div>
                                <div
                                    style={{
                                        backgroundColor: "#0085FF",
                                        color: "white",
                                        borderRadius: "100%",
                                        display: "grid",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 200,
                                        height: 200,
                                        padding: 24,
                                        textAlign: "center"
                                    }}
                                >
                                    <div style={{ paddingTop: 12, paddingLeft: 6, paddingRight: 6 }}>
                                        <label style={{ fontSize: 20, fontWeight: 400 }}>{t('home-conact-outstanding2')}</label>
                                    </div>
                                    
                                    <center>
                                        <img src={"./assets/images/icon/car-wash.png"} style={{ width: 100, height: 100 }} />
                                    </center>
                                </div>
                            </div>
                        </Col>

                        <Col
                            xs={24} md={1} xl={1}
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                            <div>
                                <img
                                    src={width > 768 ? "./assets/images/icon/right-arrow.png" : "./assets/images/icon/down-arrow.png"}
                                    style={{
                                        width: 40,
                                        height: 40,
                                        paddingRight: width > 768 ? 13 : 0,
                                        paddingTop: width > 768 ? 0 : 13
                                    }}
                                />
                            </div>
                        </Col>

                        <Col xs={24} md={7} xl={7} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div>
                                <div
                                    style={{
                                        backgroundColor: "#0085FF",
                                        color: "white",
                                        borderRadius: "100%",
                                        display: "grid",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 200,
                                        height: 200,
                                        padding: 24,
                                        textAlign: "center"
                                    }}
                                >
                                    <div style={{ paddingTop: 12, paddingLeft: 6, paddingRight: 6 }}>
                                        <label style={{ fontSize: 20, fontWeight: 400 }}>{t('home-conact-outstanding3')}</label>
                                    </div>

                                    <center>
                                        <img src={"./assets/images/icon/proactive.png"} style={{ width: 100, height: 100 }} />
                                    </center>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>


            <Modal
                title="ไลน์"
                visible={isModalLineVisible}
                onCancel={handleModalLine}
                // width={1000}
                footer={[]}
            >
                <img
                    style={{ objectFit: "cover", width: "100%", height: 500, borderRadius: 8 }}
                    src={`./assets/images/contact/line-qrcode.jpg`}
                />
            </Modal>
        </div>
    )
}