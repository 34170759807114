import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

// Vehicle
const insertVehiclesFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_VEHICLES_URL, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getVehiclesFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get(server.GET_VEHICLES_URL + `?type=${param.type}&isActive=${param.isActive}&page=${param.page}&size=${param.size}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getVehiclesByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.get(server.GET_VEHICLES_BY_ID_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateVehiclesFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.put(server.UPDATE_VEHICLES_URL + `/${param.id}`, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const deleteVehiclesByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.delete(server.DELETE_VEHICLES_BY_ID_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getVehiclesFetch,
    getVehiclesByIdFetch,

    // insert
    insertVehiclesFetch,

    // update
    updateVehiclesFetch,

    // delete
    deleteVehiclesByIdFetch
}
