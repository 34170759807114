
export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING"
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS"
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED"

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK"
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE"

export const serverUrl = "https://buddy-tour-service.witte-bangkok.com"
// export const serverUrl = "http:///localhost:3030"

export const apiServerUrl = serverUrl // + "" "/api/v1"

export const imgDefaultUrl = `./assets/images/default/df-img.png`
export const videoDefaultUrl = `./assets/images/default/df-vdo.png`
export const imgDefaltCardCourse = `./assets/images/default/df-card-course-img.png`
export const img404notfound = `./assets/images/notfound/404notfound.jpg`

export const paginate = 10000 // limit 10 k
export const sortASC = "ASC"
export const sortDESC = "DESC"

export const YES = "YES"
export const NO = "NO"
export const OK = "ok"
export const NOK = "nok"

export const server = {

    // ------------------------ CAR SERVICE ----------------------------------------------------
    GET_HOMEPAGE_CAR_SERVICES_URL: `/api/homePage/carServices`,
    GET_HOMEPAGE_TOURIST_ATTRACTIONS_URL: `/api/homePage/touristAttractions`,

    // ------------------------ VEHICLE DETAIL -------------------------------------------------
    GET_VEHICLE_DETAIL_URL: `/api/vehicleDetailPage`,
    GET_VEHICLE_DETAIL_IMAGE_URL: `/api/vehicleDetailPage/images`,

    // ------------------------ TOURIST ATTRACTIONS --------------------------------------------
    GET_TOURIST_ATTRACTIONS_URL: `/api/tourist-attractions`,
    GET_TOURIST_ATTRACTIONS_BY_ID_URL: `/api/tourist-attractions`,

    INSERT_TOURIST_ATTRACTIONS_URL: `/api/tourist-attractions`,

    UPDATE_TOURIST_ATTRACTIONS_URL: `/api/tourist-attractions`,

    DELETE_TOURIST_ATTRACTIONS_BY_ID_URL: `/api/tourist-attractions`,

    // ------------------------ VEHICLES -------------------------------------------------------
    GET_VEHICLES_URL: `/api/vehicles`,
    GET_VEHICLES_BY_ID_URL: `/api/vehicles`,

    INSERT_VEHICLES_URL: `/api/vehicles`,

    UPDATE_VEHICLES_URL: `/api/vehicles`,

    DELETE_VEHICLES_BY_ID_URL: `/api/vehicles`,

    // ------------------------ VEHICLE IMAGE --------------------------------------------------
    INSERT_VEHICLE_IMAGES_URL: `/api/vehicle-images`,

    DELETE_VEHICLE_IMAGES_BY_ID_URL: `/api/vehicle-images`,

    // ------------------------ UPLOADS --------------------------------------------------------
    UPLOAD_FILE: `/api/upload`,
    UPLOAD_FILES: `/api/upload/multiple`,

    // ------------------------ ERP ------------------------------------------------------------
    GET_ERP_SIGNIN_ID_URL: `/api/auth/signIn`,
    GET_ERP_SIGNUP_ID_URL: `/api/auth/signup`,
    GET_ERP_SIGNOUT_ID_URL: `/api/auth/signout`,
    GET_ERP_LIST_URL: ``,
}
