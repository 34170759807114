/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react"
import { useNavigate } from 'react-router-dom'
import { Row, Col, Button, Modal, AutoComplete, Pagination } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../common/mamager/ScreenManager"

import "./css/index.css"
import LoadingPage from "../../common/components/loading/page"
import Card from "../../common/components/card"
import { getHomePageCarServicesFetch } from "../home/API"
import { useTranslation } from 'react-i18next'
import { displayLanguage } from "../../common/mamager/LanguageManager"

export default function CarService(props) {
    const navigate = useNavigate()
    const { width } = useDimensions()

    const [carService, setCarService] = useState([])

    const [loading, setLoading] = useState(false)

    const { t, i18n } = useTranslation()

    const [options, setOptions] = useState([])

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(4)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const handleChange = value => {
        // console.log("value : ", value)
        pageCurrentRef.current = value
        getPropertiesBySearch(searchNameRef.current)
    }

    const onSelect = async (value) => {
        setLoading(true)

        await getPropertiesBySearch(value)

        setLoading(false)
    }

    const handleSearch = async (value) => {
        // let obj = { title: value, paging: 10 }
        const result = []
        let data = []
        result?.map((val) => data.push(val.title))
        let searchResult = await data
            .map((data) => {
                return {
                    value: data,
                    label: (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{data}</span>
                        </div>
                    )
                }
            })

        pageCurrentRef.current = 1
        setOptions(value ? searchResult : [])
    }

    const getPropertiesBySearch = async (name) => {
        setLoading(true)

        let param = {
            lang: displayLanguage(i18n.language),
        }
        let result = await getHomePageCarServicesFetch(param, null, null)
        // console.log("getHomePageCarServicesFetch : ", result)
        setCarService(result)

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getPropertiesBySearch(null)
    }

    useEffect(() => {
        getBaseApi()
    }, [i18n.language])

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: props?.background,
            }}
        >
            <div
                style={{
                    paddingTop: 84,
                    paddingBottom: 84,
                    paddingLeft: (width > 1200 ? 0 : 24),
                    paddingRight: (width > 1200 ? 0 : 24),
                    maxWidth: 1200,
                    width: "100%"
                }}
            >
                <Row>
                    <Col span={24} style={{ textAlign: "center", paddingBottom: 12 }}>
                        <label style={{ fontSize: 26 }}>{t('home-car-service-title')}</label>
                    </Col>

                    <Col span={24}>
                        <div
                            style={{
                                paddingTop: 12,
                                paddingBottom: 0
                            }}
                        >
                            {!loading ?
                                <div style={{ borderRadius: 8 }}>
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <Row gutter={[12, 12]}>
                                                {carService?.map((item, index) => {
                                                    return (
                                                        <Col xs={24} sm={12} md={8} lg={8}>
                                                            <Card
                                                                key={index}
                                                                index={index + 1}

                                                                id={item?.id}
                                                                name={item?.title}
                                                                detail={item?.detail}
                                                                image={item?.image}

                                                                heightBody={137}

                                                                isPaddingLeft={true}
                                                                isPaddingRight={true}
                                                                isPaddingTop={true}
                                                                isOnlyimage={true}
                                                            />
                                                        </Col>
                                                    )
                                                })
                                                }
                                            </Row>
                                        </Col>

                                        {total !== 0 ?
                                            <Col span={24} style={{ paddingTop: total > 12 ? 12 : 0, paddingBottom: 12, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                                <center>
                                                    <Pagination
                                                        defaultCurrent={pageCurrentRef.current}
                                                        defaultPageSize={pageSize}
                                                        onChange={handleChange}
                                                        total={total}
                                                    />
                                                </center>
                                            </Col>
                                            : []
                                        }

                                        {carService?.length === 0 ?
                                            <Col span={24}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="mdi:file-find" style={{ fontSize: '1.5em', color: "#0099ff" }} />
                                                    <label style={{ paddingLeft: 8 }}>ไม่พบบริการ</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                                :
                                <Col span={24}>
                                    <LoadingPage loading={loading} />
                                </Col>
                            }

                            {/* <RealEstateList 
                            realEstate={realEstate} 
                            loading={loading} 

                            pageCurrent={pageCurrentRef.current}
                            pageSize={pageSize}
                            total={total}
                            searchName={searchNameRef.current}
                        /> */}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}