/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    Select,
    Upload,
    InputNumber,
    Radio,
    // DatePicker 
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { apiServerUrl, server } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { deleteVehiclesByIdFetch, getVehiclesFetch, insertVehiclesFetch, updateVehiclesFetch } from './API/vehicleApi'
import { deleteVehicleImageByIdFetch, insertVehicleImageFetch } from './API/vehicleImageApi'

const { TextArea } = Input;
const { Option } = Select
// const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ชื่อประเภทภาษาไทย',
        dataIndex: 'typeTh',
        width: "20%",
    },
    {
        title: 'ชื่อภาษาภาษาไทย',
        dataIndex: 'titleTh',
        width: "20%",
    },
    {
        title: 'รูปภาษาไทย',
        dataIndex: 'imageTh',
        width: "10%",
        render: (text, record) => {
            return (
                <>
                    <img src={record.imageTh} alt={record.titleTh} style={{ width: 100 }} />
                </>
            )
        }
    },
    // {
    //     title: 'สถานะ',
    //     width: "10%",
    //     render: (text, record) => {
    //         return (
    //             <>
    //                 {record.isActive ?
    //                     <label>แสดงข้อมูล</label>
    //                     :
    //                     <label>ปิดแสดงข้อมูล</label>
    //                 }
    //             </>
    //         )
    //     }
    // },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'เเก้ไขล่าสุด',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function VehicleManage(props) {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingVehicleGallery, setLoadingVehicleGallery] = useState(false)

    const [formVehicle] = Form.useForm()
    // const [formPropertyGallery] = Form.useForm()
    const [formSearch] = Form.useForm()

    const accessToken = sessionStorage.getItem("accessToken")

    const [vehicleId, setVehicleId] = useState()

    const [vehicleGallery, setVehicleGallery] = useState([])

    const [imageVehicleThURL, setImageVehicleThURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [imageVehicleEnURL, setImageVehicleEnURL] = useState({
        loading: false,
        imageUrl: null
    })

    const isActiveVehicleGalleryRef = useRef(false)

    const [imageVehicleGalleryURL, setImageVehicleGalleryURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [serviceFeeDetailTh, setServiceFeeDetailTh] = useState()

    const [serviceFeeDetailEn, setServiceFeeDetailEn] = useState()

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const [modalVehicle, setModalVehicle] = useState({
        isShow: false,
        title: null
    })

    const optionVehicleImageTh = {
        name: 'file',
        action: `${apiServerUrl}${server.UPLOAD_FILE}?type=vehicle`,
        data: {
            name: "admin"
        },
        headers: {
            'x-access-token': accessToken
        },
        onChange(info) {

            if (info.file.status !== 'uploading') {
                let result = info.file.response
                if (result?.isSuccess) {
                    // console.log("A1 --- : ", result.formData.fileUrl)
                    setImageVehicleThURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImageVehicleThURL({
                    imageUrl: imageVehicleThURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionVehicleImageEn = {
        name: 'file',
        action: `${apiServerUrl}${server.UPLOAD_FILE}?type=vehicle`,
        data: {
            name: "admin"
        },
        headers: {
            'x-access-token': accessToken
        },
        onChange(info) {

            if (info.file.status !== 'uploading') {
                let result = info.file.response
                if (result?.isSuccess) {
                    // console.log("A1 --- : ", result.formData.fileUrl)
                    setImageVehicleEnURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImageVehicleEnURL({
                    imageUrl: imageVehicleEnURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionVehicleGalleryImage = {
        name: 'file',
        action: `${apiServerUrl}${server.UPLOAD_FILE}?type=vehicle`,
        data: {
            name: "admin"
        },
        headers: {
            'x-access-token': accessToken
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    // console.log("A2 --- : ", result.formData.fileUrl)
                    setImageVehicleGalleryURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImageVehicleGalleryURL({
                    imageUrl: imageVehicleGalleryURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setLoadingVehicleGallery(true)

                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")

                let body = {
                    vehicleId,
                    image: result.formData.fileUrl
                }
                const resultVehicleImage = await insertVehicleImageFetch(null, body, accessToken)
                isActiveVehicleGalleryRef.current = false

                let obj = {
                    vehicleId,
                    image: result.formData.fileUrl,
                    id: resultVehicleImage?.id
                }
                let tmpVehicleGallery = vehicleGallery
                tmpVehicleGallery?.push(obj)
                // console.log("tmpVehicleGallery : ", tmpVehicleGallery)
                setVehicleGallery(tmpVehicleGallery)

                setLoadingVehicleGallery(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getVehiclesAll = async (type) => {
        setLoading(true)

        let param = {
            type,
            isActive: "",
            page: pageCurrentRef.current,
            size: pageSize
        }
        const result = await getVehiclesFetch(param, null, accessToken)
        // console.log("getVehiclesFetch : ", result)
        setTotal(result?.totalItems)
        let tempList = []
        result?.results?.map((val, index) => {
            tempList.push({
                index: (pageCurrentRef.current * pageSize - 10) + (index + 1),
                imageTh: val.imageTh, // รูปภาพภาษาไทย
                imageEn: val.imageEn, // รูปภาพภาษาอังกฤษ
                typeTh: val.typeTh, // ชื่อประเภทภาษาไทย
                typeEn: val.typeEn, // ชื่อประเภทภาษาอังกฤษ
                titleTh: val.titleTh, // ชื่อภาษาไทย
                titleEn: val.titleEn, // ชื่อภาษาอังกฤษ
                isActive: val.isActive, // สถานะการใช้งาน
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                formVehicle.setFieldsValue({
                                    id: val.id, // รหัสยานพาหนะ
                                    vehicleId: val.id, // รหัสยานพาหนะ
                                    // imageTh: val.imageTh, // รูปภาพภาษาไทย
                                    // imageEn: val.imageEn, // รูปภาพภาษาอังกฤษ
                                    typeTh: val.typeTh, // ชื่อประเภทภาษาไทย
                                    typeEn: val.typeEn, // ชื่อประเภทภาษาอังกฤษ
                                    titleTh: val.titleTh, // ชื่อภาษาไทย
                                    titleEn: val.titleEn, // ชื่อภาษาอังกฤษ
                                    detailTh: val.detailTh, // รายละเอียดภาษาไทย
                                    detailEn: val.detailEn, // รายละเอียดภาษาอังกฤษ
                                    isActive: val.isActive, // สถานะการใช้งาน
                                    serviceFeeDetailTh: val.serviceFeeDetailTh,
                                    serviceFeeDetailEn: val.serviceFeeDetailEn
                                })

                                setImageVehicleThURL({
                                    loading: false,
                                    imageUrl: val.imageTh
                                })

                                setImageVehicleEnURL({
                                    loading: false,
                                    imageUrl: val.imageEn
                                })

                                setVehicleId(val.id)
                                // console.log("vehicleId : ", val.id)

                                setVehicleGallery(val.vehicleImages)

                                // setVideoProductDetailURL({
                                //     loading: false,
                                //     videoUrl: val.videoURL
                                // })

                                setServiceFeeDetailTh(val.serviceFeeDetailTh ?? "")

                                setServiceFeeDetailEn(val.serviceFeeDetailEn ?? "")

                                setModalVehicle({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleVehiclesDelete(val.id)

                                // reload
                                await getVehiclesAll("")
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)
        searchNameRef.current = type

        setLoading(false)
    }

    const onVehicleFinish = async (values) => {
        let param = {
            id: values.id ? values.id : ""
        }

        let body = {
            "slugName": "slugName",
            "imageTh": imageVehicleThURL.imageUrl ? imageVehicleThURL.imageUrl : "",
            "imageEn": imageVehicleEnURL.imageUrl ? imageVehicleEnURL.imageUrl : "",
            "typeTh": values.typeTh ? values.typeTh : "",
            "typeEn": values.typeEn ? values.typeEn : "",
            "titleTh": values.titleTh ? values.titleTh : "",
            "titleEn": values.titleEn ? values.titleEn : "",
            "detailTh": values.detailTh ? values.detailTh : "",
            "detailEn": values.detailEn ? values.detailEn : "",
            "serviceFeeDetailTh": values.serviceFeeDetailTh ? values.serviceFeeDetailTh : "",
            "serviceFeeDetailEn": values.serviceFeeDetailEn ? values.serviceFeeDetailEn : "",
            "isActive": values.isActive ? 1 : 0,
        }
        // console.log("body : ", body)

        if (modalVehicle.title === "add") {
            const result = await insertVehiclesFetch(null, body, accessToken)
            if (result && result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modalVehicle.title === "edit") {
            const result = await updateVehiclesFetch(param, body, accessToken)
            if (result && result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getVehiclesAll("")

        // set default
        setFormVehicleDefault()
    }

    // const onPropertyGalleryFinish = async (values) => {

    // }

    const onSearchFinish = async (values) => {
        let type = values?.type ? values.type : ""
        await getVehiclesAll(type)
    }

    const handleVehiclesDelete = async (id) => {
        let param = {
            id
        }
        const result = await deleteVehiclesByIdFetch(param, null, accessToken)
        if (result && result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleVehiclesGalleryDelete = async (id) => {
        let param = {
            id
        }
        // console.log("param : ", param)
        const result = await deleteVehicleImageByIdFetch(param, null, accessToken)
        if (result && result.isSuccess) {

            let tmpVehicleGallery = vehicleGallery?.filter(fill => fill.id !== id)
            // console.log("tmpVehicleGallery : ", tmpVehicleGallery)
            setVehicleGallery(tmpVehicleGallery)

            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        pageCurrentRef.current = n.current
        getVehiclesAll(searchNameRef.current)
    }

    const setFormVehicleDefault = () => {
        formVehicle.setFieldsValue({
            id: undefined, // รหัสยานพาหนะ
            vehicleId: undefined, // รหัสยานพาหนะ
            // imageTh: undefined, // รูปภาพภาษาไทย
            // imageEn: undefined, // รูปภาพภาษาอังกฤษ
            typeTh: undefined, // ชื่อประเภทภาษาไทย
            typeEn: undefined, // ชื่อประเภทภาษาอังกฤษ
            titleTh: undefined, // ชื่อภาษาไทย
            titleEn: undefined, // ชื่อภาษาอังกฤษ
            detailTh: undefined, // รายละเอียดภาษาไทย
            detailEn: undefined, // รายละเอียดภาษาอังกฤษ
            isActive: undefined, // สถานะการใช้งาน
            serviceFeeDetailTh: undefined,
            serviceFeeDetailEn: undefined
        })

        setImageVehicleThURL({
            loading: false,
            imageUrl: null
        })

        setImageVehicleEnURL({
            loading: false,
            imageUrl: null
        })

        setImageVehicleGalleryURL({
            loading: false,
            imageUrl: null
        })

        // setVideoProductDetailURL({
        //     loading: false,
        //     videoUrl: null
        // })

        setServiceFeeDetailTh("")

        setServiceFeeDetailEn("")

        setModalVehicle({
            isShow: false,
            title: null
        })
    }

    // const setFormPropertyGalleryDefault = () => {
    //     // formPropertyGallery.setFieldsValue({
    //     //     vehicleId: undefined,
    //     // })

    //     setModalPropertyGallery({
    //         isShow: false,
    //         title: null
    //     })
    // }

    const getBaseApi = async () => {
        getVehiclesAll("")
    }

    // console.log("vehicleGallery : ", vehicleGallery)

    useEffect(() => {
        // console.log("accessToken : ", accessToken)
        getBaseApi()
    }, [])

    useEffect(() => {

    }, [vehicleGallery])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการรถที่ให้บริการ</label>
            </Col>

            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                // label="ชื่ออสังหา"
                                name="type"
                                style={{ width: '100%' }}
                            >
                                <Input placeholder="ค้นหาชื่อประเภทรถที่ให้บริการ" />
                            </Form.Item>
                        </div>

                        {/* <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ช่วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div> */}

                        <div style={{ paddingLeft: 10, marginTop: -24 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        formVehicle.resetFields()

                        setVehicleGallery([])

                        setModalVehicle({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrentRef.current,
                        pageSize: pageSize,
                        total: total
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modalVehicle.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modalVehicle.isShow}
                zIndex={999}
                onCancel={() => {

                    // default
                    setFormVehicleDefault()
                }}
                width={"90%"}
                onOk={() => {
                    formVehicle.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formVehicle}
                    onFinish={onVehicleFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} xl={12}>
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <Form.Item name="id" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="vehicleId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="typeTh"
                                        label="ประเภทรถภาษาไทย"
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อประเภทรถภาษาไทย' }]}
                                    >
                                        <Input placeholder='ประเภทรถภาษาไทย'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="typeEn"
                                        label="ประเภทรถภาษาอังกฤษ"
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อประเภทรถภาษาอังกฤษ' }]}
                                    >
                                        <Input placeholder='ประเภทรถภาษาอังกฤษ'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="titleTh"
                                        label="ชื่อรถภาษาไทย"
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อรถภาษาไทย' }]}
                                    >
                                        <Input placeholder='ชื่อรถภาษาไทย'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="titleEn"
                                        label="ชื่อรถภาษาอังกฤษ"
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อรถภาษาอังกฤษ' }]}
                                    >
                                        <Input placeholder='ชื่อรถภาษาอังกฤษ'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="detailTh"
                                        label="รายละเอียดรถภาษาไทย"
                                        rules={[{ required: true, message: 'กรุณากรอกรายละเอียดรถภาษาไทย' }]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3, maxRows: 6 }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="detailEn"
                                        label="รายละเอียดรถภาษาอังกฤษ"
                                        rules={[{ required: true, message: 'กรุณากรอกรายละเอียดรถภาษาอังกฤษ' }]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3, maxRows: 6 }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="serviceFeeDetailTh"
                                        label="รายละเอียดค่าบริการรถภาษาไทย"
                                        rules={[{ required: true, message: 'กรุณากรอกรายละเอียดค่าบริการรถภาษาไทย' }]}
                                    >

                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        'fontfamily', 'fontsize', '|',
                                                        'bold', 'italic', 'underline', '|',
                                                        'alignment', '|',
                                                        'fontColor', 'fontBackgroundColor', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        'code', 'codeBlock', '|',
                                                        'undo', 'redo', '|',
                                                        'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells'
                                                    ],
                                                    removeButtons: 'Subscript,Superscript'
                                                },
                                                table: {
                                                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                                                },
                                                height: 350
                                            }}
                                            data={serviceFeeDetailTh}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData();
                                                formVehicle.setFieldValue('serviceFeeDetailTh', data);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="serviceFeeDetailEn"
                                        label="รายละเอียดค่าบริการรถภาษาอังกฤษ"
                                        rules={[{ required: true, message: 'กรุณากรอกรายละเอียดค่าบริการรถภาษาอังกฤษ' }]}
                                    >
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        'fontfamily', 'fontsize', '|',
                                                        'bold', 'italic', 'underline', '|',
                                                        'alignment', '|',
                                                        'fontColor', 'fontBackgroundColor', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        'code', 'codeBlock', '|',
                                                        'undo', 'redo', '|',
                                                        'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells'
                                                    ],
                                                    removeButtons: 'Subscript,Superscript'
                                                },
                                                table: {
                                                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                                                },
                                                height: 350
                                            }}
                                            data={serviceFeeDetailEn}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData();
                                                formVehicle.setFieldValue('serviceFeeDetailEn', data);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Row gutter={[24, 0]}>
                                <Col xs={24} md={12} xl={12}>
                                    <div style={{ display: "grid" }}>
                                        <label style={{ paddingBottom: 6 }}>ภาพปกรถภาษาไทย</label>
                                        {imageVehicleThURL?.imageUrl ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                                src={`${imageVehicleThURL.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                                            <Upload
                                                {...optionVehicleImageTh}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imageVehicleThURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <div style={{ display: "grid" }}>
                                        <label style={{ paddingBottom: 6 }}>ภาพปกรถภาษาอังกฤษ</label>
                                        {imageVehicleEnURL?.imageUrl ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                                src={`${imageVehicleEnURL.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                                            <Upload
                                                {...optionVehicleImageEn}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imageVehicleEnURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                {modalVehicle.title !== "add" ?
                                    <Col span={24} style={{ paddingTop: 24 }}>
                                        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                            <label>รายการรูปรถ</label>

                                            {/* <Button
                                            type="primary"
                                            style={{ float: 'right' }}
                                            onClick={() => {
                                                isActiveVehicleGalleryRef.current = true
                                                setModalPropertyGallery({
                                                    isShow: true,
                                                    title: "เพิ่มภาพอสังหาฯ"
                                                })
                                            }}
                                        >เพิ่มรูปผลงาน</Button> */}

                                            <Upload
                                                {...optionVehicleGalleryImage}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    type="primary"
                                                    style={{ width: "100%" }}
                                                    icon={imageVehicleGalleryURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >เพิ่มรูปผลงาน</Button>
                                            </Upload>

                                        </div>
                                        <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                            <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 16 }}>
                                                <Row gutter={[12, 24]}>
                                                    {!loadingVehicleGallery ?
                                                        <>
                                                            {vehicleGallery?.map((val, index) => {
                                                                return (
                                                                    <Col xs={24} md={12} xl={12}>
                                                                        <img
                                                                            // src={`${serverUrl}/${val.image}`}
                                                                            src={`${val.image}`}
                                                                            style={{ objectFit: "cover", width: "100%", height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                                                                        />
                                                                        <Button
                                                                            type="primary"
                                                                            style={{ width: '100%' }}
                                                                            onClick={async () => {
                                                                                await handleVehiclesGalleryDelete(val.id)
                                                                            }}
                                                                        >ลบรูปภาพ</Button>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </>
                                                        : []
                                                    }

                                                    {vehicleGallery?.length === 0 ?
                                                        <Col span={24}>
                                                            <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                                <label>ไม่มีรูปภาพ</label>
                                                            </div>
                                                        </Col>
                                                        : []
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    : []
                                }

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="isActive"
                                        label="เเสดงสินค้าหรือไม่ ?"
                                        rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            <Option key={0} value={true}>เปิด</Option>
                                            <Option key={1} value={false}>ปิด</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
