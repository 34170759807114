/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import Main from './components/main'
import Contact from './components/contact'
import {
    Row,
    Col
} from 'antd'
import { Element, scroller } from 'react-scroll'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import { useLocation } from 'react-router'
import Blog from '../blog'
import Footer from '../../common/components/footer'
import Gallery from '../../common/components/gallery'
import TouristAttraction from './components/touristAttraction'
import CarService from '../carService'

export default function Home() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const menu = params.get("menu")

    const { width } = useDimensions()

    const [previousWork, setPreviousWork] = useState([])

    const [loading, setLoading] = useState(false)

    // const genScroll = () => {
    //     if (menu === "home") {
    //         scroller.scrollTo("home", {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -78,
    //         })
    //     } else if (menu === "our-services") {
    //         scroller.scrollTo("our-services", {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -78,
    //         })
    //     } else if (menu === "product") {
    //         scroller.scrollTo("product", {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -78,
    //         })
    //     }
    // }

    const getPreviousWorkAll = async () => {
        let result = [
            {
                image: "./assets/images/past_work/pw1.jpg"
            },
            {
                image: "./assets/images/past_work/pw2.jpg"
            }
        ] // await getPreviousWorkAllFetch()
        // console.log("getPreviousWorkAllFetch : ", result)
        if (result) {
            setPreviousWork(result)
        }
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getPreviousWorkAll()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
        // genScroll()
    }, [])

    return (
        <>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        {/* <Element name=""> */}
                            <Main />
                        {/* </Element> */}
                    </Col>
                    <Col span={24}>
                        {/* <Element name=""> */}
                            <Contact />
                        {/* </Element> */}
                    </Col>
                    <Col span={24}>
                        {/* <Element name=""> */}
                            <CarService background={"white"} />
                        {/* </Element> */}
                    </Col>
                    <Col span={24}>
                        {/* <Element name=""> */}
                            <TouristAttraction />
                        {/* </Element> */}
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}
