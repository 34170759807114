/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card } from 'antd'
import { Icon } from '@iconify/react'

import { getHomePageTouristAttractionsFetch } from "../../API"
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import LoadingPage from "../../../../common/components/loading/page"
import { useTranslation } from 'react-i18next'
import { displayLanguage } from "../../../../common/mamager/LanguageManager"

export default function TouristAttraction(props) {
    const navigate = useNavigate()
    const { width } = useDimensions()

    const [touristAttraction, setTouristAttraction] = useState([])

    const [loading, setLoading] = useState(false)

    const { t, i18n } = useTranslation()

    const getPropertiesBySearch = async (name) => {
        setLoading(true)

        let param = {
            lang: displayLanguage(i18n.language)
        }
        let result = await getHomePageTouristAttractionsFetch(param, null, null)
        // console.log("getHomePageTouristAttractionsFetch : ", result)
        setTouristAttraction(result)

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getPropertiesBySearch(null)
    }

    useEffect(() => {
        getBaseApi()
    }, [i18n.language])

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#0085FF",
                color: "white"
            }}
        >
            <div
                style={{
                    paddingTop: 84,
                    paddingBottom: 84,
                    paddingLeft: (width > 1200 ? 0 : 24),
                    paddingRight: (width > 1200 ? 0 : 24),
                    maxWidth: 1200,
                    width: "100%"
                }}
            >
                <Row>
                    <Col span={24} style={{ textAlign: "center", paddingBottom: 12 }}>
                        <label style={{ fontSize: 26 }}>{t('home-tourist-attraction-title')}</label>
                    </Col>

                    <Col span={24}>
                        <div
                            style={{
                                paddingTop: 12,
                                paddingBottom: 0
                            }}
                        >
                            {!loading ?
                                <div style={{ borderRadius: 8 }}>
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <Row gutter={[12, 12]}>
                                                {touristAttraction?.map((item, index) => {
                                                    return (
                                                        <Col xs={24} sm={12} md={8} lg={8}>
                                                            <div>
                                                                <Card hoverable>
                                                                    <label style={{ color: "#0085FF" }}>{item.title}</label>
                                                                </Card>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                                }
                                            </Row>
                                        </Col>

                                        {touristAttraction?.length === 0 ?
                                            <Col span={24}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="mdi:file-find" style={{ fontSize: '1.5em', color: "#0099ff" }} />
                                                    <label style={{ paddingLeft: 8 }}>ไม่พบบริการ</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                                :
                                <Col span={24}>
                                    <LoadingPage loading={loading} />
                                </Col>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}