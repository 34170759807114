/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../header/css/index.css"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
	Drawer,
	Button,
	Menu,
	Popover,
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"
import { useTranslation } from 'react-i18next'

export default function Header(props) {
	const navigate = useNavigate()
	const { width } = useDimensions()

	const [state, setState] = useState({
		current: 'mail',
		visible: false
	})

	const showDrawer = () => {
		setState({
			visible: true
		})
	}

	const onClose = () => {
		setState({
			visible: false
		})
	}

	const { t, i18n } = useTranslation()

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng)
	};

	const genRightMenu = () => {
		return (
			<Menu mode="inline">
				<Menu.Item
					key="1"
					onClick={() => {
						openPath("home")
					}}
				>
					{t('home-title')}
				</Menu.Item>

				<Menu.Item
					key="2"
					onClick={() => {
						openPath("about-us")
					}}
				>
					{t('about-us-title')}
				</Menu.Item>

				<Menu.Item
					key="3"
					onClick={() => {
						openPath("contact-us")
					}}
				>
					{t('contact-us-title')}
				</Menu.Item>

				<Menu.Item
					key="4"
				// onClick={() => {

				// }}
				>

				</Menu.Item>
			</Menu>
		)
	}

	const content = (
		<div style={{ display: "grid" }}>
			<div style={{ paddingBottom: 6 }}>
				<Button style={{ width: "100%" }} onClick={() => changeLanguage('en')}>English</Button>
			</div>
			<div>
				<Button style={{ width: "100%" }} onClick={() => changeLanguage('th')}>Thai</Button>
			</div>
		</div>
	)

	const genLeftMenu = () => {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					fontSize: 18,
					marginTop: -2
				}}
			>
				<div
					onClick={() => {
						openPath("home")
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>{t('home-title')}</label>
					</div>
				</div>

				<div
					onClick={() => {
						openPath("about-us")
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>{t('about-us-title')}</label>
					</div>
				</div>

				<div
					onClick={() => {
						openPath("contact-us")
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>{t('contact-us-title')}</label>
					</div>
				</div>
			</div>
		)
	}

	const openPath = (menu) => {
		if (menu === "home") {
			navigate("/")
		} else if (menu === "about-us") {
			navigate("/about-us")
		} else if (menu === "contact-us") {
			navigate("/contact-us")
		}
	}

	const genLanguage = () => {
		return (
			<div style={{ color: "white", paddingLeft: 12, paddingRight: width < 1012 ? 12 : 0 }}>
				<Popover placement="bottom" content={content}>
					{["th", "th-TH"].includes(i18n.language) && (
						<div style={{ display: "flex", alignItems: "center" }}>
							<label style={{ cursor: "pointer" }}>TH</label>
							<Icon icon="icon-park-outline:down" style={{ fontSize: 25, color: "white", cursor: "pointer" }} />
							<img src={"./assets/images/language/thailand.png"} style={{ width: 25, cursor: "pointer" }} />
						</div>
					)}

					{["en", "en-US"].includes(i18n.language) && (
						<div style={{ display: "flex", alignItems: "center" }}>
							<label style={{ cursor: "pointer" }}>EN</label>
							<Icon icon="icon-park-outline:down" style={{ fontSize: 25, color: "white", cursor: "pointer" }} />
							<img src={"./assets/images/language/united-kingdom.png"} style={{ width: 25, cursor: "pointer" }} />
						</div>
					)}
				</Popover>
			</div>
		)
	}

	return (
		<div
			style={{
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
				paddingRight: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
				position: 'fixed',
				zIndex: 2,
				width: width > 1200 ? "80%" : (width > 760 && width < 1200 ? "100%" : "100%"),
				backgroundColor: '#0085FF',
				// overflow: 'hidden',
				boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
				height: 70,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between"
			}}
		>
			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ display: "flex", alignItems: "center", color: "white" }}>
					<img
						src={"./assets/images/logo/logo.jpg"}
						style={{ width: 55, height: 55, borderRadius: 60, cursor: "pointer" }}
						onClick={() => {
							navigate("/")
						}}
					/>
					<label style={{ paddingLeft: 10 }} >BUDDY TOUR SERVICE</label>
				</div>
			</div>

			{width > 1012 ?
				<div
					style={{
						display: "flex",
						paddingTop: 2,
						alignItems: "center",
						justifyContent: "flex-end"
					}}
				>
					{genLeftMenu()}
					{genLanguage()}
				</div>
				:
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
					{genLanguage()}
					<Drawer
						title={<label style={{ fontSize: 16, fontWeight: 100 }}>Buddy tour service</label>}
						placement="right"
						closable={false}
						onClose={onClose}
						visible={state.visible}
						bodyStyle={{ padding: '0px' }}
						size="default"
					>
						{genRightMenu()}
					</Drawer>

					<Button
						type="primary"
						onClick={showDrawer}
						style={{
							height: 70,
							width: 85,
							borderRadius: 0
						}}
					>
						<Icon
							icon="charm:menu-hamburger"
							style={{
								fontSize: '2.5em',
								marginTop: 4,
								marginLeft: -8,
								color: "white"
							}}
						/>
					</Button>
				</div>
			}
		</div>
	)
}