import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

// TouristAttraction
const insertTouristAttractionFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_TOURIST_ATTRACTIONS_URL, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getTouristAttractionFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get(server.GET_TOURIST_ATTRACTIONS_URL + `?title=${param.title}&isActive=${param.isActive}&page=${param.page}&size=${param.size}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getTouristAttractionByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.get(server.GET_TOURIST_ATTRACTIONS_BY_ID_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateTouristAttractionFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.put(server.UPDATE_TOURIST_ATTRACTIONS_URL + `/${param.id}`, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const deleteTouristAttractionByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.delete(server.DELETE_TOURIST_ATTRACTIONS_BY_ID_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getTouristAttractionFetch,
    getTouristAttractionByIdFetch,

    // insert
    insertTouristAttractionFetch,

    // update
    updateTouristAttractionFetch,

    // delete
    deleteTouristAttractionByIdFetch
}
