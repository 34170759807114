/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
    Row,
    Col,
    Button
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { scroller } from "react-scroll"
import { useTranslation } from 'react-i18next'

export default function Main() {

    const { width } = useDimensions()

    const { t, i18n } = useTranslation()

    return (
        <>
            <div
                style={{
                    backgroundImage: width > 1366 ? "url(./assets/images/background/bg-car.jpg)" :
                        (width <= 1366 && width > 1065 ? "url(./assets/images/background/bg-car-md.jpg)" :
                            (width <= 1065 && width > 767 ? "url(./assets/images/background/bg-car-sm.jpg)" :
                                "url(./assets/images/background/bg-car-vsm.jpg)")),
                    backgroundSize: (width > 760 ? '100%' : '255%'),
                    width: '100% auto',
                    height: '100% auto',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        // display: "flex",
                        // justifyContent: width > 760 ? "space-between" : "center",
                        paddingTop: width > 1200 ? 150 : (width > 760 && width < 1200 ? 150 : 150),
                        paddingBottom: 82,
                        paddingLeft: (width > 1200 ? "12%" : 24),
                        paddingRight: (width > 1200 ? "12%" : 24),
                        maxWidth: 1200,
                        width: "100%"
                    }}
                >
                    <div style={{ height: width > 430 ? 500 : "100%" }}>
                        <div
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                                borderRadius: 16,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 24,
                                paddingBottom: 24
                            }}
                        >
                            <div style={{ display: width > 645 ? "flex" : "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                {/* <img src={"./assets/images/logo/logo-is-asset-home.png"} style={{ width: (width > 645 ? 200 : "100%"), height: (width > 645 ? 200 : "100%"), borderRadius: 16 }} /> */}

                                <div style={{ display: "grid" }}>
                                    <div style={{ display: "flex", alignItems: "flex-start", paddingBottom: 12 }}>
                                        <div><Icon icon="dashicons:yes" style={{ fontSize: 36, color: "#0085FF" }} /></div>
                                        <label style={{ paddingLeft: 8, fontSize: 24 }}>{t('main-highlight1')}</label>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "flex-start", paddingBottom: 12 }}>
                                        <div><Icon icon="dashicons:yes" style={{ fontSize: 36, color: "#0085FF" }} /></div>
                                        <label style={{ paddingLeft: 8, fontSize: 24 }}>{t('main-highlight2')}</label>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                                        <div><Icon icon="dashicons:yes" style={{ fontSize: 36, color: "#0085FF" }} /></div>
                                        <label style={{ paddingLeft: 8, fontSize: 24 }}>{t('main-highlight3')}</label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}