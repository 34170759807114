/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import Header from '../../common/components/header'
import { useDimensions } from '../../common/mamager/ScreenManager'
import parse from 'html-react-parser'
import { useLocation } from 'react-router'
import Footer from '../../common/components/footer'
import Gallery from '../../common/components/gallery'
import { FaPhoneAlt } from "react-icons/fa"
import { Icon } from '@iconify/react'
import { getVehicleDetailFetch, getVehicleDetailImageFetch } from './API'
import { useTranslation } from 'react-i18next'
import { displayLanguage } from '../../common/mamager/LanguageManager'

export default function CarServiceDetail() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const carServiceId = params.get("carServiceId")

    const [loading, setLoading] = useState(false)

    const { width } = useDimensions()
    const [vehicleDetail, setVehicleDetail] = useState()
    const [vehicleGallery, setVehicleGallery] = useState([])

    const { t, i18n } = useTranslation()

    const getVehicleDetail = async () => {
        let param = {
            lang: displayLanguage(i18n.language),
            id: Number(carServiceId)
        }
        let result = await getVehicleDetailFetch(param, null, null)
        // console.log("getVehicleDetailFetch : ", result[0])
        if (result?.length > 0) {
            setVehicleDetail(result[0])
        }
    }

    const getVehicleDetailImage = async () => {
        let param = {
            id: Number(carServiceId)
        }
        let result = await getVehicleDetailImageFetch(param, null, null)
        // console.log("getVehicleDetailImageFetch : ", result)
        if (result?.length > 0) {
            setVehicleGallery(result)
        }
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getVehicleDetail()
        await getVehicleDetailImage()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [i18n.language])

    return (
        <>
            <Header />

            <div
                style={{
                    backgroundColor: "#EEEEEE",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        paddingTop: 70,
                        // paddingLeft: (width > 1200 ? 0 : 24),
                        // paddingRight: (width > 1200 ? 0 : 24),
                        maxWidth: 1200,
                        width: "100%"
                    }}
                >
                    {!loading ?
                        <>
                            <Row
                                gutter={[0, 24]}
                                style={{
                                    backgroundColor: "white",
                                    paddingTop: 24,
                                    paddingBottom: 84,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                }}
                            >
                                <Col span={24}>
                                    <label style={{ fontWeight: 600, fontSize: 20, color: "#0085FF" }}>{vehicleDetail?.title ?? "-"}</label>
                                </Col>

                                <Col xs={24} md={12} xl={8}>
                                    <div style={{ paddingBottom: 6 }}>
                                        <img
                                            src={`${vehicleDetail?.image}`}
                                            style={{ objectFit: "cover", width: "100%", height: 400, borderRadius: 8 }}
                                        />
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div style={{ paddingBottom: 6 }}>
                                        <label style={{ fontWeight: 600 }}>{t('car-service-details')}</label>
                                    </div>
                                    <div style={{ paddingBottom: 12 }}>
                                        <label>{vehicleDetail?.detail ? parse(vehicleDetail?.detail ?? "-") : "-"}</label>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div style={{ paddingBottom: 12 }}>
                                        <label>{vehicleDetail?.serviceFeeDetail ? parse(vehicleDetail?.serviceFeeDetail ?? "-") : "-"}</label>
                                    </div>
                                </Col>
                            </Row>

                            <div style={{}}>
                                {vehicleGallery?.length > 0 ?
                                    <Gallery
                                        typeId={1}
                                        loading={loading}
                                        data={vehicleGallery}
                                        typeName={"Car gallery"}
                                        isOpenPaddingLeft={false}
                                        isOpenPaddingRight={false}
                                        backgroundColor={"white"}
                                        isOpenBaseApi={false}
                                    />
                                    : []
                                }
                            </div>

                        </>
                        : []
                    }
                </div>
            </div>

            <Footer />
        </>
    )
}
