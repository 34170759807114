import "./styles/App.css"
import "./styles/App.less"

import Home from "./modules/home"
import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import themeAntd from './styles/themeAntd'
import LoginERP from "./modules/erp/login"
import ERP from "./modules/erp"
import CarServiceDetail from "./modules/carServiceDetail"
import AboutUs from "./modules/aboutUs"
import ContactUs from "./modules/contactUs"
import './i18n'

const App = ({ authorized, firebase, user }) => (
    <ConfigProvider theme={{ token: themeAntd }}>
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/car-service-detail" element={<CarServiceDetail />} />
                <Route exact path="/about-us" element={<AboutUs />} />
                <Route exact path="/contact-us" element={<ContactUs />} />
                
                <Route exact path="/login-buddy-tour-service-back-office" element={<LoginERP authorized={authorized} firebase={firebase} user={user} />} />
                <Route exact path="/buddy-tour-service-back-office" element={<ERP authorized={authorized} firebase={firebase} user={user} />} />
                <Route path="/:id">Page not found!</Route>
            </Routes>
        </Router>
    </ConfigProvider>
)

export default App
