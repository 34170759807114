/* eslint-disable jsx-a11y/alt-text */
import { serverUrl } from "../../../constants"
import "./css/index.css"
import React from 'react'
import { Button, Col, Image, Row } from 'antd'
import { Icon } from '@iconify/react'
import { useNavigate } from "react-router"
import { useTranslation } from 'react-i18next'

export default function Card(props) {

    const navigate = useNavigate()

    const { t, i18n } = useTranslation()

    return (
        <div
            style={{
                // paddingLeft: 12,
                // paddingRight: (props.index % 10) ? 0 : 12,
                // paddingTop: 12,
                // paddingBottom: 12,
                width: props.width,
            }}
        >
            <div className="blog-card">
                {props?.isOnlyimage ?
                    <>
                        <div
                            style={{
                                borderRadius: 8,
                                width: "100%",
                                height: props?.height
                            }}
                            onClick={() => {
                                // if (props.pageType === "real-estate") {
                                    navigate("/car-service-detail?carServiceId=" + props.id)
                                // }

                                window.scrollTo(0, 0)
                            }}
                        >

                            <div style={{ paddingBottom: 6 }}>
                                <img
                                    src={`${props?.image}`}
                                    style={{ objectFit: "cover", width: "100%", height: 300, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                                />
                            </div>

                            <div style={{ height: props?.heightBody }}>
                                <div
                                    style={{
                                        paddingLeft: 12,
                                        paddingRight: 6,
                                        paddingBottom: 10,
                                        fontWeight: 600
                                    }}
                                >
                                    <div className="blog-title">
                                        <label style={{ cursor: "pointer" }}>{props?.name}</label>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        paddingLeft: 12,
                                        paddingRight: 6,
                                        paddingBottom: 10,
                                        fontWeight: 400
                                    }}
                                >
                                    <div className="blog-title">
                                        <label style={{ cursor: "pointer" }}>{props?.detail}</label>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingBottom: 12,
                                    float: 'right'
                                }}
                            >

                                <Button
                                    type="primary"
                                    onClick={() => {
                                        if (props.pageType === "real-estate") {
                                            navigate("/car-service-detail?carServiceId=" + props.id)
                                        }

                                        window.scrollTo(0, 0)
                                    }}
                                >
                                    <label style={{ cursor: "pointer" }}>{t('home-car-service-detail')}</label>
                                </Button>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <Image
                            fallback={props?.isOpenBaseApi ? `${serverUrl}/${props?.image}` : `${props?.image}`}
                            src="error"
                            style={{ objectFit: "cover", width: "100%", height: 300, borderRadius: 8, border: "3px solid #0085FF" }}
                        />
                    </>
                }

            </div>
        </div >
    )
}
