/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    Select,
    Upload,
    // DatePicker   
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { insertTouristAttractionFetch, getTouristAttractionFetch, updateTouristAttractionFetch, deleteTouristAttractionByIdFetch } from './API/touristAttractionApi'

const { Option } = Select

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ชื่อสถานที่ท่องเที่ยวภาษาไทย',
        dataIndex: 'titleTh',
        width: "20%",
    },
    {
        title: 'ชื่อสถานที่ท่องเที่ยวภาษาอังกฤษ',
        dataIndex: 'titleEn',
        width: "20%",
    },
    {
        title: 'สถานะ',
        width: "10%",
        render: (text, record) => {
            return (
                <>
                    {record.isActive ?
                        <label>เปิดใช้งาน</label>
                        :
                        <label>ปิดใช้งาน</label>
                    }
                </>
            )
        }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'เเก้ไขล่าสุด',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function TouristAttractionManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [formTouristAttraction] = Form.useForm()
    const [formSearch] = Form.useForm()

    const accessToken = sessionStorage.getItem("accessToken")

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const [modalTouristAttraction, setModalTouristAttraction] = useState({
        isShow: false,
        title: null
    })

    const getTouristAttactionAll = async (title) => {
        setLoading(true)

        let param = {
            title,
            isActive: "",
            page: pageCurrentRef.current,
            size: pageSize
        }
        const result = await getTouristAttractionFetch(param, null, accessToken)
        // console.log("getTouristAttractionFetch : ", result?.results)
        setTotal(result?.totalItems)
        let tempList = []
        result?.results?.map((val, index) => {
            tempList.push({
                index: (pageCurrentRef.current * pageSize-10) + (index + 1),
                titleTh: val.titleTh,
                titleEn: val.titleEn,
                isActive: val.isActive,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                formTouristAttraction.setFieldsValue({
                                    id: val.id,
                                    isActive: val.isActive,
                                    titleTh: val.titleTh,
                                    titleEn: val.titleEn,
                                })

                                setModalTouristAttraction({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleTouristAttractionDelete(val.id)

                                // reload
                                await getTouristAttactionAll("")
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)
        searchNameRef.current = title

        setLoading(false)
    }

    const onTouristAttractionFinish = async (values) => {
        let param = {
            id: values.id ? values.id : ""
        }

        let body = {
            "titleTh": values.titleTh ? values.titleTh : "",
            "titleEn": values.titleEn ? values.titleEn : "",
            "isActive": values.isActive ? 1 : 0,
        }
        // console.log("body : ", body)

        if (modalTouristAttraction.title === "add") {
            const result = await insertTouristAttractionFetch(null, body, accessToken)
            if (result && result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modalTouristAttraction.title === "edit") {
            const result = await updateTouristAttractionFetch(param, body, accessToken)
            if (result && result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getTouristAttactionAll("")

        // set default
        setFormTouristAttractionDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : ""
        await getTouristAttactionAll(title)
    }

    const handleTouristAttractionDelete = async (id) => {
        let param = {
            id
        }
        const result = await deleteTouristAttractionByIdFetch(param, null, accessToken)
        if (result && result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        pageCurrentRef.current = n.current
        getTouristAttactionAll(searchNameRef.current)
    }

    const setFormTouristAttractionDefault = () => {
        formTouristAttraction.setFieldsValue({
            id: undefined,
            titleTh: undefined,
            titleEn: undefined,
            isActive: undefined
        })

        setModalTouristAttraction({
            isShow: false,
            title: null
        })
    }

    const getBaseApi = async () => {
        getTouristAttactionAll("")
    }
    
    useEffect(() => {
        // console.log("accessToken : ", accessToken)
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการสถานที่ท่องเที่ยว</label>
            </Col>

            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                // label="ชื่ออสังหา"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input placeholder="ค้นหาชื่อสถานที่ท่องเที่ยว" />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: -24 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        formTouristAttraction.resetFields()

                        setModalTouristAttraction({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrentRef.current,
                        pageSize: pageSize,
                        total: total
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modalTouristAttraction.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modalTouristAttraction.isShow}
                zIndex={999}
                onCancel={() => {

                    // default
                    setFormTouristAttractionDefault()
                }}
                width={700}
                onOk={() => {
                    formTouristAttraction.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formTouristAttraction}
                    onFinish={onTouristAttractionFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="id" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="titleTh"
                                label="ชื่อสถานที่ท่องเที่ยวภาษาไทย"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อสถานที่ท่องเที่ยวภาษาไทย' }]}
                            >
                                <Input placeholder="ชื่อสถานที่ท่องเที่ยวภาษาไทย" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="titleEn"
                                label="ชื่อสถานที่ท่องเที่ยวภาษาอังกฤษ"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อสถานที่ท่องเที่ยวภาษาอังกฤษ' }]}
                            >
                                <Input placeholder="ชื่อสถานที่ท่องเที่ยวภาษาอังกฤษ" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="isActive"
                                label="เเสดงบทความนี้หรือไม่ ?"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>เปิด</Option>
                                    <Option key={1} value={false}>ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
